





























import {Vue, Component, Prop} from 'vue-property-decorator';
import CompanySearch from "@/components/common/CompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import CreateW9CRPayload, {CreateW9CRContractorData} from "@/dto/request/w9cr/CreateW9CRPayload";
import W9CRService from "@/services/request/W9CRService";
import {namespace} from "vuex-class";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import EmploymentSearch from "@/components/common/EmploymentSearch.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {OptionsBuilder} from "@/components/common/SelectOption";

const AppModule = namespace("App");

@Component({
  computed: {
    OptionsBuilder() {
      return OptionsBuilder
    }
  },
  components: {PortalSelect, EmploymentSearch, PortalCheckbox, CompanySearch, PortalInput}
})
export default class CreateW9CR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  onCreated!: () => void

  company: CompanyDTO | null = null;

  contractors: Array<CreateW9CRContractorData> = [];

  newContractor = new CreateW9CRContractorData();

  addRegistered = false;
  contractorType = "";

  selectedContractor: IEmployment | null = null;

  addContractor(){
    this.contractors.push(this.newContractor);
    this.newContractor = new CreateW9CRContractorData();
    this.selectedContractor = null;
  }

  create(){
    this.startLoading();
    const payload = new CreateW9CRPayload({companyId: this.company?.id, contractors: this.contractors});
    W9CRService.create(payload).then(
        ok => {
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  contractorChanged(contractor: IEmployment){
    this.newContractor.id = contractor.id!;
    this.newContractor.type = contractor.details.type!;
    this.newContractor.name = contractor.details.name!;
  }

  removeContractor(index: number) {
    this.contractors.splice(index, 1);
  }

  get showAddButton(): boolean {
    return !this.addRegistered && !!this.newContractor.name || this.addRegistered && !!this.selectedContractor;
  }

}
