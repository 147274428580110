import {EmploymentType} from "@/constants/EmploymentType";
import {AccountType} from "@/constants/AccountTypes";

export default class CreateW9CRPayload {

    private companyId!: number;
    private contractors!: Array<CreateW9CRContractorData>;

    constructor(init?: Partial<CreateW9CRPayload>) {
        Object.assign(this, init);
    }

}

export class CreateW9CRContractorData {
    id?: number;
    type?: AccountType;
    name?: string;
}